import { Box, Link, Text, VStack, Table, Tbody, Tr, Td, TableContainer } from '@chakra-ui/react';

import pageContent from './about-us.json';

export const AboutUs = () => {
  const { contact } = pageContent;

  return (
    <Box mt={6}>
      <Box><Text fontWeight="bold">Board of Directors:</Text></Box>
      <Box>The Board of Directors is elected on a 2-year cycle from the Club membership to oversee the operations, finances, and future direction of the Club. These Members volunteer their time to serve as officers and directors to help ensure a quality ongoing experience for all of the Club's Members.</Box>
      <Box><TableContainer my="16px">
        <Table size='sm'>
          <Tbody>
            <Tr>
              <Td>President</Td>
              <Td>David Thrower</Td>
              <Td>djthrower@msn.com</Td>
            </Tr>
            <Tr>
              <Td>Vice President</Td>
              <Td>Scott Turnage</Td>
              <Td>sturned@msn.com</Td>
            </Tr>
            <Tr>
              <Td>Treasurer</Td>
              <Td>Mark Cunnings</Td>
              <Td>goldenclubsecretary@gmail.com</Td>
            </Tr>
            <Tr>
              <Td>Secretary</Td>
              <Td>Chris Randall</Td>
              <Td>randallrealm@comcast.net</Td>
            </Tr>
            <Tr>
              <Td>Senior Executive</Td>
              <Td>Larry Eckblad</Td>
              <Td>larry@eckblad.com</Td>
            </Tr>
            <Tr>
              <Td>Club Instructor</Td>
              <Td>Paul Royer</Td>
              <Td>royer5563@gmail.com</Td>
            </Tr>
            <Tr>
              <Td>Shotgun Manager</Td>
              <Td>Paul Pedri</Td>
              <Td>shotgunggc@gmail.com</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer></Box>

      <Box><Text fontWeight="bold">Match Directors:</Text></Box>
      <Box>Golden Gun Club's match programs are overseen by volunteers from the membership whose passion for their disciplines have inspired them to give their time to ensure a fair, fun, and challenging experience for both Members and the public. Match Directors are appointed by the Board of Directors.</Box>
      <Box><TableContainer my="16px">
        <Table size='sm'>
          <Tbody>
            <Tr>
              <Td>Black Powder Cartridge</Td>
              <Td>Mike Rix</Td>
              <Td>rmrix@hotmail.com</Td>
            </Tr>
            <Tr>
              <Td>Cowboy Silhouette</Td>
              <Td>Rich DelPizzo</Td>
              <Td>303-917-2875</Td>
            </Tr>
            <Tr>
              <Td>Service Rifle</Td>
              <Td>Matt Anthony</Td>
              <Td>statmatt@gmail.com, 720-775-5243</Td>
            </Tr>
            <Tr>
              <Td>Bowling Pin Pistol</Td>
              <Td>Jared Pedri</Td>
              <Td>jared@effectiveft.com</Td>
            </Tr>
            <Tr>
              <Td>NRA Action Pistol</Td>
              <Td>Jim Flagg</Td>
              <Td>b737jim@aol.com</Td>
            </Tr>
            <Tr>
              <Td>Shotgun/Clubhouse</Td>
              <Td>Paul Pedri</Td>
              <Td>303-366-6970</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer></Box>

      <Box><Text fontWeight="bold">{contact.heading}</Text></Box>
      <Box>
        <VStack align="flex-start">
          <Box>{contact.address}</Box>
          <Box>{contact.phoneOne}<br />{contact.phoneTwo}</Box>
          <Box><Link href={`mailto:${contact.email}`}>{contact.email}</Link></Box>
        </VStack>
      </Box>
      <Box p="24px 0">
        <iframe title="Golden Gun club Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d392972.15216102207!2d-104.88614016857603!3d39.692569299999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876cf59c393028fb%3A0x44253d4150be40d2!2s1503%20S%20Watkins%20Rd%2C%20Watkins%2C%20CO%2080137!5e0!3m2!1sen!2sus!4v1667873170539!5m2!1sen!2sus" width="100%" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      </Box>
    </Box>
  )
}